<script>

export default {
  data() {
    return {
      submit_loading: false,      
    };
  },
  mounted() {
    
    
  },
  methods: {
    setLoading(value){
      this.submit_loading = value;
    },
    customValidate() {
        return true;
    }, 
    customValidateComboBoxField(item,name,label, allow_nullable = true, needs_id = true) {
      //if (!((!allow_nullable && item == null) || (item != null && Object.prototype.hasOwnProperty.call(item,"id") && item.id > 0))) {
      if (
        (!allow_nullable && item == null) || //No se permite vacio
        (!allow_nullable && item == "") || //No se permite vacio
        (item != null && needs_id && !Object.prototype.hasOwnProperty.call(item,"id")) || //No selecciono un item
        (item != null && Object.prototype.hasOwnProperty.call(item,"id") && !(item.id > 0))//Error de seleccion
      ) {        
          this.$toast.error("Debe seleccionar "+label+" del listado", "Error");
          this.$validator.errors.add({
            field: name,
            msg: 'Debe seleccionar '+label+' del listado'
          });        
          return false;
      }
      return true;
    },
    showError(name, msg){
      this.$toast.error(msg, "Error");
      this.$validator.errors.add({
        field: name,
        msg: msg
      });
    },
    removeErrorFromValidator(name){
      this.$validator.errors.remove(name);
    },

  },
};
</script>
