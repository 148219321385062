<template>
  <div class="login">
    <div class="row bg justify-content-md-center align-items-center">
      <div class="col-md-4">
        <v-card :dark="true" :flat="true" class="rounded-0">
          <v-card-title class="justify-content-center">
            <v-img :src="require('@/assets/images/logo.png')" contain max-width="200" class="pt-10" />
          </v-card-title>
          <v-card-text class="padding">
            <v-form>
              <p v-if="login_error != ''" class="red--text">
                Los datos ingresados son incorrectos. Vuelve a intentarlo
              </p>
              <v-text-field label="Username" v-model="username" prepend-icon="mdi-account" v-on:keyup.enter="auth" />
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                v-on:keyup.enter="auth"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="padding">
            <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="auth" :loading="loading">Login</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/authService";
import Constant from "@/constants/";
export default {
  name: "App",

  components: {},

  data: () => ({
    login_error: "",
    username: "",
    password: "",
    show: false,
    loading: false,
  }),

  methods: {
    auth: function(event) {
      this.loading = true;
      authService
        .login(this.username, this.password)
        .then((response) => {
          console.log(response);
          if (!response.status) {
            this.login_error = response.msg;
          } else {
            localStorage.setItem("token", response.result.token);
            localStorage.setItem("expire_in", response.result.expire_in);
            localStorage.setItem("username", response.result.username);
            localStorage.setItem("redirect", response.result.redirect);
            const encryptedText = this.CryptoJS.AES.encrypt(JSON.stringify(response.result.access), Constant.SECRET_PHRASE).toString();

            localStorage.setItem("access", encryptedText);
            //this.$router.push("/");
            this.$router.go("/");
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
