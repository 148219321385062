import { wsService } from "./wsService";

var section = "air-agent-invoices";

function filter(param) {
  return wsService.make_post_request(section + "/filter", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}


export const agenteAirInvoiceService = {
  filter,
  update,
};
