<template>
  <div>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-0 fs-16 text-center">
            {{ text }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="$emit('updateDialog', false)"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('action')"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    text: String,
    dialog: Boolean,    
    action: Function,
    updateDialog: Function,
  },
  data: () => ({}),
  methods: {},
};
</script>
