<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', $event.target.value)" max-width="750px">
      
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                
                <div class="col-md-12">
                  <v-expansion-panels flat v-model="panel">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="mb-0 title-acordion">
                          <b>Agent's Invoices</b>
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>                      
                        <InputTable     
                          :validator="$validator"
                          :isViewModal="isViewModal()"                     
                          table_title="Agent's Invoices"
                          :headers="[{ text: 'Invoice Nº' }, { text: 'Attachment' }, { text: 'Action' }]"
                          :items="item.facturas_agentes"
                          :input_fields="[
                            { width: '20%', label: 'Invoice Nº*', name: 'nro_factura', type: 'text', required: false, validate: 'max:30' },
                            {
                              label: 'Select invoice',
                              name: 'factura_agente_attached',
                              name_attached: 'factura_path',
                              type: 'file',
                              required: false,
                              allowed_mime_types: [
                                'application/pdf',
                                'image/jpg',
                                'image/png'
                              ],                              
                            },
                          ]"
                          :item_template="{
                            id: 0,
                            nro_factura: '',
                            factura_agente_attached: null,
                          }"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div> 
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalMixin from "@/mixins/ModalMixin.vue";
import InputTable from "@/components/InputTable.vue";

export default {
  mixins: [ ModalMixin ],
  components: {
    InputTable,
  },
  ModalMixin,
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    validator: Object,
  },
  data() {
    return {
      show: false,

      panel: 0,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);      
    },
    dialog() {
      console.log(this.item);
      this.errors.clear();
    },
  },
  mounted() {        
    
  },
  methods: {
    isViewModal() {
      console.log(this.formBtn);
      return this.formBtn == "";
    },
    getData() {
      console.log(this.item);

      let data = new FormData();
      data.append("id", this.item.id);
     // data.append("operacion", this.item.operacion);
          
      data.append("cantidad_facturas_agente", this.item.facturas_agentes.length);
      if (this.item.facturas_agentes.length > 0) {
        for (var j = 0; j < this.item.facturas_agentes.length; j++) {
          data.append("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
          data.append("facturas_agentes_" + j + "_nro_factura", this.item.facturas_agentes[j].nro_factura);
          data.append("facturas_agentes_" + j + "_attached", this.item.facturas_agentes[j].factura_agente_attached);
          console.log("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id)
        }
      }  
      
      return data;
    },
    createItem(e) {
      if(this.loading)
        return;
      this.setLoading(true);      
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
                    
          if (this.item.id > 0) {

            const data = this.getData();

            return this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => this.setLoading(false));
          }
        }
      });
    },
  },
};
</script>
