<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @print="print"
        @loadList="filter"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "        
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0 ">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0 ">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="shipper_selected"
                    autocomplete="off"
                    data-vv-as="shipper"
                    name="shipper"
                    item-text="name"
                    item-value="id"
                    :items="shippers"
                    label="Shipper"
                    class="pt-0 mt-0"
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="consignee_selected"
                    autocomplete="off"
                    data-vv-as="consignee"
                    name="consignee"
                    item-text="name"
                    item-value="id"
                    :items="consignees"
                    label="Consignee"
                    class="pt-0 mt-0"
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="guia_madre_selected" autocomplete="off" data-vv-as="guia_madre" name="guia_madre" label="Guia Madre" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="guia_hija_selected" autocomplete="off" data-vv-as="guia_hija" name="guia_hija" label="Guia Hija" class="pt-0 mt-0"></v-text-field>
                </div>              
                <div class="col-md-6 pt-0" style="display: flex;justify-content: end;">
                  <v-btn color="blue-grey" class="mr-2 text-white" @click="export_filter">Exportar</v-btn>
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..." hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.guia_madre }}</td>
              <td>{{ props.item.guia_hija }}</td>
              <td>{{ props.item.executed_date }}</td>
              <td>{{ props.item.consignee.name }}</td>
              <td class="justify-content-center px-0">
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="#6e6e6e" class="mr-2" @click="print(props.item, 'print')">
                      print
                    </v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>-->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openHistoryAutorizaciones(props.item, 'Autorizaciones')">timer</v-icon>
                  </template>
                  <span>Ver Historial Autorización Facturas Agente</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="purple" class="mr-2" @click="print(props.item, 'pdf')">
                      mdi-arrow-down-bold-box-outline
                    </v-icon>
                  </template>
                  <span>Descargar PDF</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey" class="mr-2" @click="copy(props.item)">
                      content_copy
                    </v-icon>
                  </template>
                  <span>Copiar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <DialogLoading :dialog="dialog_print" text="Imprimiendo..." />
      <HistoryModalTemplate
        v-if="dialog_historial_autorizaciones"
        :dialog="dialog_historial_autorizaciones"
        title="Autorizaciones"
        :id="item_selected.id"
        :service="service"
        method="history_autorizaciones"
        :item="item_selected"

        :headers="[
          { text: 'Fecha', align: 'left', sortable: true, value: 'fecha_modificacion' },
          { text: 'Factura Agente', value: 'factura_agente.nro_factura' },          
          { text: 'Autorizado', value: 'autorizado' },   
          { text: 'Usuario', value: 'user.username' }
        ]"
        :properties="[
          'fecha_modificacion',
          'nro_factura',
          'autorizado',
          'username'
        ]"
        @close="closeHistoryAutorizaciones"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/";
import { generalService } from "@/libs/ws/generalService";
import { orderService } from "@/libs/ws/orderService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import HistoryModalTemplate from "@/components/HistoryModalTemplate.vue";

export default {
  components: { Title, Modal, DialogDelete, DialogLoading, HistoryModalTemplate},
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: orderService,
      page_title: "Ordenes Aéreas",
      modal_title: "Orden Aérea",
      mainItem: {
        id: "",
        guia_madre: "",
        guia_hija: "",
        agente: null,
        shipper: null,
        consignee: null,
        inssuing_carrier: null,
        departure_airport: null,

        transshipment_airport: null,
        carrier: null,
        accounting_information: "",
        currency: null,
        chgs_code: "",
        wtval_ppd: null,
        wtval_coll: null,
        other_ppd: null,
        other_coll: null,
        declared_value_for_carriage: "",
        declared_value_for_customs: "",

        arrival_airport: null,
        requested_flight: "",
        amount_insurance: "",
        handling_information: "",

        rcp: "",
        gross_weight: "",
        kilograms: "",
        rate_class: "",
        chargeable_weight: "",
        rate_charge: "",
        total: "",
        nature_quantity_goods: "",
        other_charges: "",
        total_other_charges: "",
        executed_date: "",

        sim: null,
        mani: null,
        comentarios: null,
        doc: null,
        vueloc_tca: null,
        fecha_etd: null,
        fecha_eta: null,
        arribo: null,
        pr_fc: null,

        facturas_agentes: [],
        documentaciones_entrega: [],
      },
      defaultItem: null,
      headers: [
        { text: "Numero", value: "number" },
        {
          text: "Guia Madre",
          align: "left",
          sortable: true,
          value: "guia_madre",
        },
        {
          text: "Guia Hija",
          align: "left",
          sortable: true,
          value: "guia_hija",
        },
        {
          text: "Executed Date",
          align: "left",
          sortable: true,
          value: "executed_date",
        },
        {
          text: "Consignee",
          align: "left",
          sortable: true,
          value: "consignee",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      dialog_print: false,
      shippers: [],
      consignees: [],
      shipper_selected: null,
      consignee_selected: null,
      guia_madre_selected: "",
      guia_hija_selected: "",
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),

      dialog_historial_autorizaciones: false,
      item_selected: null,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = Object.assign({}, this.mainItem);
    this.filter();
  },
  methods: {
    getFilterParams() {
      const param = {
        shipper: this.shipper_selected?.id,
        consignee: this.consignee_selected?.id,
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        guia_madre: this.guia_madre_selected,
        guia_hija: this.guia_hija_selected,
      };
      return param;
    },
    delete(service) {
      service
        .remove({ "id": this.deleteId })
        .then((response) => {
          if (response.status === true){
            this.$toast.success(response.msg, "OK");
            this.filter();
          }
          else{
            if(Object.prototype.hasOwnProperty.call(response, 'message')){
              this.$toast.error(response.message, "Error");
            }else{
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service, this.getFilterParams());
    },
    updateDialog(value, next_flag = false) {
      if (!value) {
        this.closeModal(next_flag);
      } else {
        this.dialog = value;
      }
    },
    closeModal(next_flag) {
      if (!next_flag) {
        this.dialog = false;
        this.editedIndex = -1;
        this.viewdIndex = -1;
        this.errors.clear();
        this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.documentaciones_entrega = [];
        this.facturas_agentes = [];
        this.close();
        return;
      }

      const mainItem_aux = this.mainItem;

      this.mainItem = Object.assign({}, this.defaultItem);

      this.mainItem.shipper = mainItem_aux.shipper;
      this.mainItem.consignee = mainItem_aux.consignee;
      this.mainItem.inssuing_carrier = mainItem_aux.inssuing_carrier;
      this.mainItem.departure_airport = mainItem_aux.departure_airport;
      this.mainItem.arrival_airport = mainItem_aux.arrival_airport;
      this.mainItem.carrier = mainItem_aux.carrier;
      this.mainItem.currency = mainItem_aux.currency;
      this.mainItem.transshipment_airport = mainItem_aux.transshipment_airport;

      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
      this.errors.clear();
    },
    export_filter() {
      orderService.export_filter(this.getFilterParams()).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
        } catch (e) {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "reporte.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          //link.download = code+'.pdf'
          link.click();
        }
      });
    },
    copy(orden) {
      this.errors.clear();

      console.log(orden);

      this.mainItem.shipper = orden.shipper;
      this.mainItem.consignee = orden.consignee;
      this.mainItem.inssuing_carrier = orden.inssuing_carrier;
      this.mainItem.departure_airport = orden.departure_airport;
      this.mainItem.arrival_airport = orden.arrival_airport;
      this.mainItem.carrier = orden.carrier;
      this.mainItem.currency = orden.currency;
      this.mainItem.transshipment_airport = orden.transshipment_airport;

      this.dialog = true;
    },
    print(orden, type) {
      this.dialog_print = true;
      orderService.print(orden.id, type).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
          this.dialog_print = false;
        } catch (e) {
          this.dialog_print = false;
          let blob = new Blob([response], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = orden.guia_madre + ".pdf";
          link.target = "_blank";
          link.click();
        }
      });
    },
    openHistoryAutorizaciones(order){
      this.item_selected = order;
      this.dialog_historial_autorizaciones = true;
    },
    closeHistoryAutorizaciones(){
      this.dialog_historial_autorizaciones = false;
      this.item_selected = null;
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
