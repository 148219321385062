var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-dialog',{attrs:{"value":_vm.dialog,"max-width":"750px"},on:{"click:outside":function($event){return _vm.$emit('updateDialog', false)},"input":function($event){return _vm.$emit('updateDialog', $event.target.value)}}},[_c('v-card',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createItem.apply(null, arguments)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.modal_title))])]),_c('v-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-12"},[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('p',{staticClass:"mb-0 title-acordion"},[_c('b',[_vm._v("Agent's Invoices")])])]),_c('v-expansion-panel-content',[_c('InputTable',{attrs:{"validator":_vm.$validator,"isViewModal":_vm.isViewModal(),"table_title":"Agent's Invoices","headers":[{ text: 'Invoice Nº' }, { text: 'Attachment' }, { text: 'Action' }],"items":_vm.item.facturas_agentes,"input_fields":[
                          { width: '20%', label: 'Invoice Nº*', name: 'nro_factura', type: 'text', required: false, validate: 'max:30' },
                          {
                            label: 'Select invoice',
                            name: 'factura_agente_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: [
                              'application/pdf',
                              'image/jpg',
                              'image/png'
                            ],                              
                          } ],"item_template":{
                          id: 0,
                          nro_factura: '',
                          factura_agente_attached: null,
                        }}})],1)],1)],1)],1)])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.$emit('updateDialog', false)}}},[_vm._v("Cerrar")]),(!_vm.isViewModal())?_c('v-btn',{attrs:{"color":"success","loading":_vm.submit_loading,"text":"","type":"submit"}},[_vm._v(_vm._s(_vm.formBtn))]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }