<template>
  <div>
    <Title :pages="[{ icon: 'chart-bar', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid v-if="list_access">
      <v-form>
        <div class="row filter justify-content-md-center mt-10">
          <div class="col-md-5">                   
            <div class="row mt-10" style="justify-content:center ; display: flex ; text-align: center">                                   

              <div class="col-md-12">
                <v-text-field
                  label="Desde*"
                  data-vv-as="desde"
                  name="desde_selected"
                  v-model="desde_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("desde_selected") }}</span>
              </div>
            
            
              <div class="col-md-12">
                <v-text-field
                  label="Hasta*"
                  data-vv-as="hasta"
                  name="hasta_selected"
                  v-model="hasta_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("hasta_selected") }}</span>                
                </div>  
                
                <div class="col-md-12">
                <v-combobox       
                  multiple         
                  v-model="agente"
                  data-vv-as="agente"
                  name="agente"
                  item-text="name"
                  item-value="id"
                  v-validate="'required'"
                  :items="agentes"
                  label="Agente*"
                  autocomplete="off"                  
                >
                  <template v-slot:prepend-item>
                  <v-list-item @click="selectAllAgentes">
                    <v-list-item-content>
                    <v-btn small color="primary">Seleccionar Todos</v-btn>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  </template>
                </v-combobox>
                <span class="red--text">{{ errors.first("agente") }}</span>
                </div>
              
            </div>

            <div class="row mb-2" style="justify-content:center ; display: flex ; text-align: center">              
              <v-btn :loading="loading_reporte" @click="generateReport()" color="primary">
                Descargar
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/";
import Title from "@/components/Title.vue";
import { orderService } from "@/libs/ws/orderService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title },
  mixins: [GenericMixin,RolMixin],
  data() {
    return {   
      page_title: "Reportes Ordenes Aereas",            
      loading_reporte: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      service: orderService, 
      agentes: [],
      agente: null,   
    };
  },
  mounted() {
    this.loadCombos();    
  },
  methods: {
    selectAllAgentes() {
      this.agente = this.agentes;
    },
    loadCombos() {      
      generalService.filter({}, "agentes").then((response) => {
        this.agentes = response.result;
      });
    },
    generateReport(){
      if(this.desde_selected == "" || this.hasta_selected == ""){
        this.$toast.error("Debe completar los campos requeridos", "Error");
        return;
      }

      this.$validator.validate().then((valid) => {
        if (!valid ) {
          this.$toast.error("Debe completar los campos requeridos", "Error");
          return;
        }

        this.loading_reporte = true;
        this.service.report(this.getFilterParams()).then((response) => {
          try {
            //Transformo a string la respuesta para poder enviar mensajes de error.
            let result = JSON.parse(this.arrayBufferToString(response));
            if (!result.status) {
              this.$toast.error(result.msg, "Error");
            }
          } catch (e) {
            let blob = new Blob([response], {
              type: "application/vnd.ms-excel",
            });
            let link = document.createElement("a");
            //link.setAttribute('download', "export")
            link.download = this.getExportName()+".xlsx";
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            //link.download = code+'.pdf'
            link.click();
          }
        })
        .catch((error) => {
          this.$toast.error("Error al descargar el reporte", "Error");
        })
        .finally(() => {
          this.loading_reporte = false;
        });
      });
    },
    getExportName(){
      //Get now Y-m-d string
      return "Reporte_Aereas_"+this.desde_selected+"_"+this.hasta_selected;      
    },
    getFilterParams() {
      let param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        agentes: this.agente.map((item) => item.id),
      };     

      return param;
    },
  },
};
</script>
