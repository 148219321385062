<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', $event.target.value)" max-width="750px"> 
      <v-card>
        <v-form v-on:submit.prevent="UpdateItems">
          <v-card-title>
            <span class="headline">Actualizar Fecha Llegada ETA</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12 mt-5">
                <label>Se actualizará la Fecha de Llegada ETA de {{ items.length }} ordenes</label>
              </div>              
              <div class="col-md-12">
                <DatePickerEsp
                  label="Fecha Llegada (ETA)"
                  :date="fecha_llegada_eta"
                  :isRequired="true"
                  name="fecha_llegada_eta"
                  :isViewModal="false"
                  @setDate="
                    (date) => {
                      fecha_llegada_eta = date;
                    }
                  "
                />
                <span class="red--text">{{ errors.first("fecha_llegada_eta") }}</span>                                                
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit">Actualizar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import ModalMixin from "@/mixins/ModalMixin.vue";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  mixins: [ModalMixin],
  ModalMixin,
  components: {
    DatePickerEsp,
  },
  props: {
    parent: Object,
    service: Object,    
    dialog: Boolean,
    items: Array,
    updateItem: Function,
    loaloadList: Function,
  },
  data() {
    return {
      show: false,
      fecha_llegada_eta: null,      
    };
  },
  watch: {    
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {        
    
  },
  methods: { 
    UpdateItems(e) {
      if(this.loading)
        return;
      this.setLoading(true);
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();          
          if (this.items.length > 0) {
            let data = {
              ids: [],
              fecha_llegada_eta: this.fecha_llegada_eta,
            }

            this.items.forEach((item) => {
              data.ids.push(item.id);
            });

            this.service
              .massiveUpdateFechaLlegadaETA(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success(response.msg, "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> this.setLoading(false));
          }else{
            this.$toast.error("No hay ordenes para actualizar", "Error");
            this.setLoading(false);
          }
        }
      });
    },
  },
};
</script>
