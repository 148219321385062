import { wsService } from "./wsService";

var section = "";

function getEnTransito(param) {
  return wsService.make_get_request(section + "/en_transito", param);
}

function filter(param) {
  return wsService.make_post_request(section + "/filter", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function print(order_id) {
  return wsService.make_post_request(section + "/print",{ id: order_id } , {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function getNextOperacion() {
  return wsService.make_get_request(section + "/next_operacion");
}

function closeOperacion(id) {
  return wsService.make_post_request(section + "/close", { id: id });
}

function massiveUpdateFechaLlegadaETA(data) {
  return wsService.make_post_request(section + "/masive_update_fecha_llegada_eta", data);
}

function setSection(s) {
  section = s;
}

function history_autorizaciones(param) {
  return wsService.make_post_request(section + "/historial-autorizaciones", param);
}

export const ordersGeneralService = {
  section,
  setSection, 
  filter,
  remove,
  create,
  update,
  print,
  export_filter,
  getNextOperacion,
  closeOperacion,
  massiveUpdateFechaLlegadaETA,  
  getEnTransito,
  history_autorizaciones,
};
