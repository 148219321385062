<script>
// definir un objeto mixin
export default {
  data() {
    return {
      show: true,
      dialog: false,
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      dialog_delete: false,
      search: "",
      rowNumber: 0,
      deleteIndex: 0,
      deleteId: 0,
      editedIndex: -1,
      viewdIndex: -1,
      loading: false,
      list: [],
      cant_pages: 0,
      current_page: 1,
      name_filter: "",
      active_filter: 1,
    };
  },
  created: function() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  computed: {
    formBtn() {
      if (this.editedIndex === -1 && this.viewdIndex === -1) {
        return "Crear";
      } else if (this.viewdIndex === -1) {
        return "Editar";
      }
      return "";
    },
  },
  mounted() {},
  methods: {
    customValidate() {
        return true;
    },    
    updateDialog(value) {
      if (!value) {
        this.close();
      }
      this.dialog = value;
    },
    updateDialogDelete(value) {
      this.dialog_delete = value;
    },
    viewItem(item) {
      this.viewdIndex = this.list.indexOf(item);
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    editItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem =JSON.parse(JSON.stringify( item));
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteIndex = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_delete = true;
    },
    close() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
    },
    back() {
      this.$router.back();
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    loadList(service, param = {}) {
      this.loading = true;
      service
        .filter(param)
        .then((response) => {          
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    delete(service) {
      service
        .remove({ "id": this.deleteId })
        .then((response) => {
          if (response.status === true){
            this.$toast.success(response.msg, "OK");
            this.loadList(service);
          }
          else{
            if(Object.prototype.hasOwnProperty.call(response, 'message')){
              this.$toast.error(response.message, "Error");
            }else{
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
  },
};
</script>
