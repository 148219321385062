<template>
    <v-dialog v-model="dialog_history" max-width="600px" @keydown.esc="close()">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Historial de {{ name }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headers" :items="history" item-key="id">
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item[field_date_name] }}</td>
                            <td
                                style="min-width:135px"
                                v-html="props.item[field_value_name].name"
                            ></td>       
                            <td>{{ props.item.user.username }}</td>                                                 
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="close()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "HistoryModal",
    props: {},
    data() {
        return {            
            dialog_history: false,

            name: "",           
            history: [],
            headers: [],
            field_date_name: "",
            field_value_name: "",
        };
    },
    mounted() {
        
    },
    computed: {},
    methods: {
        resetObject() {
            //this.mainItem = Object.assign({}, this.defaultItem);
        },
        close() {
            this.dialog_history = false;
            this.resetObject();
        },
        open(name,headers,history_list,field_date_name,field_value_name) {
            this.dialog_history = true
            this.name = name;
            this.history = history_list
            this.headers = headers;  
            this.field_date_name = field_date_name;
            this.field_value_name = field_value_name; 
            
            this.headers.push(
                { text: "Usuario", value: "user.username" },
            );
        },
        /*
        get_history(assigned_id) {            
            if (assigned_id > 0) {
                
                assignatesTaskService.get_history_state_assigned_task(assigned_id)
                    .then(response => {    
                        console.log(response)
                        this.history = response.result;
                        this.dialog_history = true;
                    })
                    .catch(function(error) {
                        alert(error.request);
                        this.loading = false;
                    });
            }
        },  
        */      
    }
};
</script>
