<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="guia_madre" label="Guia Madre*" name="guia_madre" v-model="item.guia_madre" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("guia_madre") }}</span>
                <v-text-field data-vv-as="guia_hija" label="Guia Hija*" name="guia_hija" v-model="item.guia_hija" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("guia_hija") }}</span>
                
                <v-combobox
                  v-model="item.agente"
                  data-vv-as="agente"
                  name="agente"
                  item-text="name"
                  item-value="id"
                  :items="agentes"
                  label="Agente*"
                  v-validate="'required'"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("agente") }}</span> 
                
                <DatePickerEsp
                  label="Executed Date*"
                  :date="item.executed_date"
                  :isRequired="true"
                  name="executed_date"
                  :isViewModal="isViewModal()"
                  :validator="$validator"
                  @setDate="
                    (date) => {
                      item.executed_date = date;
                    }
                  "
                />

                <v-combobox
                  v-model="item.shipper"
                  data-vv-as="shipper"
                  name="shipper"
                  item-text="name"
                  item-value="id"
                  :items="shippers"
                  label="Shipper*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("shipper") }}</span>

                <v-combobox
                  v-model="item.consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  :items="consignees"
                  label="Consignee*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>

                <v-combobox
                  v-model="item.inssuing_carrier"
                  data-vv-as="inssuing_carrier"
                  name="inssuing_carrier"
                  item-text="name"
                  item-value="id"
                  :items="inssuing_carriers"
                  label="Inssuing Carrier"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("inssuing_carrier") }}</span>

                <v-combobox
                  v-model="item.departure_airport"
                  data-vv-as="departure_airport"
                  name="departure_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Departure Airport"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("departure_airport") }}</span>

                <v-combobox
                  v-model="item.transshipment_airport"
                  data-vv-as="transshipment_airport"
                  name="transshipment_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Transshipment Airport"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("transshipment_airport") }}</span>

                <v-combobox
                  v-model="item.carrier"
                  data-vv-as="carrier"
                  name="carrier"
                  item-text="name"
                  item-value="id"
                  :items="carriers"
                  label="By First Carrier"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("carrier") }}</span>
                <v-textarea
                  outlined
                  data-vv-as="accounting_information"
                  label="Accounting Information"
                  name="accounting_information"
                  v-model="item.accounting_information"                  
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("accounting_information") }}</span>

                <v-combobox
                  v-model="item.currency"
                  data-vv-as="currency"
                  name="currency"
                  item-text="name"
                  item-value="id"
                  :items="currencies"
                  label="Currency"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("currency") }}</span>

                <v-text-field data-vv-as="chgs_code" label="CHGS Code" name="chgs_code" v-model="item.chgs_code" :disabled="isViewModal()"></v-text-field>

                <div class="row">
                  <div class="col-md-12">
                    <label>WT/VAL</label>
                    <v-checkbox label="PPD" :disabled="isViewModal()" v-model="item.wtval_ppd"></v-checkbox>
                    <v-checkbox label="COLL" :disabled="isViewModal()" v-model="item.wtval_coll"></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Other</label>
                    <v-checkbox label="PPD" :disabled="isViewModal()" v-model="item.other_ppd"></v-checkbox>
                    <v-checkbox label="COLL" :disabled="isViewModal()" v-model="item.other_coll"></v-checkbox>
                  </div>
                </div>

                <v-text-field
                  data-vv-as="declared_value_for_carriage"
                  label="Declared Value for carriage"
                  name="declared_value_for_carriage"
                  v-model="item.declared_value_for_carriage"
                  :disabled="isViewModal()"
                ></v-text-field>

                <v-text-field
                  data-vv-as="declared_value_for_customs"
                  label="Declared Value for customs"
                  name="declared_value_for_customs"
                  v-model="item.declared_value_for_customs"
                  :disabled="isViewModal()"
                ></v-text-field>

                <v-combobox
                  v-model="item.arrival_airport"
                  data-vv-as="arrival_airport"
                  name="arrival_airport"
                  item-text="name"
                  item-value="id"
                  :items="airports"
                  label="Destination Airport"
                  autocomplete="off"                  
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("arrival_airport") }}</span>
                <v-text-field
                  data-vv-as="requested_flight"
                  label="Requested Flight"
                  name="requested_flight"
                  v-model="item.requested_flight"                  
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("requested_flight") }}</span>
                <v-text-field data-vv-as="amount_insurance" label="Amount Insurance" name="amount_insurance" v-model="item.amount_insurance" :disabled="isViewModal()"></v-text-field>

                <v-textarea
                  outlined
                  data-vv-as="handling_information"
                  label="Handling Information"
                  name="handling_information"
                  v-model="item.handling_information"                  
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("handling_information") }}</span>

                <v-text-field data-vv-as="rcp" label="No of pieces RCP" name="rcp" v-model="item.rcp" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rcp") }}</span>
                <v-text-field data-vv-as="gross_weight" label="Gross Weight" name="gross_weight" v-model="item.gross_weight" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("gross_weight") }}</span>
                <v-text-field data-vv-as="kilograms" label="Kg" name="kilograms" v-model="item.kilograms" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("kilograms") }}</span>
                <v-text-field data-vv-as="rate_class" label="Rate class" name="rate_class" v-model="item.rate_class" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rate_class") }}</span>
                <v-text-field
                  data-vv-as="chargeable_weight"
                  label="Chargeable weight"
                  name="chargeable_weight"
                  v-model="item.chargeable_weight"                  
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("chargeable_weight") }}</span>
                <v-text-field data-vv-as="rate_charge" label="Rate charge" name="rate_charge" v-model="item.rate_charge" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("rate_charge") }}</span>
                <v-text-field data-vv-as="total" label="Total" name="total" v-model="item.total" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("total") }}</span>
                <v-textarea
                  outlined
                  data-vv-as="nature_quantity_goods"
                  label="Nature quantity of goods"
                  name="nature_quantity_goods"
                  v-model="item.nature_quantity_goods"
                  
                  :disabled="isViewModal()"
                ></v-textarea>
                <span class="red--text">{{ errors.first("nature_quantity_goods") }}</span>

                <v-text-field data-vv-as="other_charges" label="Other charges" name="other_charges" v-model="item.other_charges" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("other_charges") }}</span>
                <v-text-field
                  data-vv-as="total_other_charges"
                  label="Total other charges"
                  name="total_other_charges"
                  v-model="item.total_other_charges"                  
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("total_other_charges") }}</span>                

                
                <v-text-field v-validate="'max:20'" max data-vv-as="sim" label="SIM" name="sim" v-model="item.sim" :disabled="isViewModal()" />
                <v-text-field v-validate="'max:10'" max data-vv-as="mani" label="MANI" name="mani" v-model="item.mani" :disabled="isViewModal()" />
                <v-textarea
                  outlined
                  data-vv-as="comentarios"
                  label="Comentarios"
                  name="comentarios"
                  v-model="item.comentarios"                  
                  :disabled="isViewModal()"
                ></v-textarea>
                <v-textarea
                  outlined
                  data-vv-as="doc"
                  label="DOC"
                  name="doc"
                  v-model="item.doc"                  
                  :disabled="isViewModal()"
                ></v-textarea>
                <v-text-field v-validate="'max:5'" max data-vv-as="vueloc_tca" label="Vueloc TCA" name="vueloc_tca" v-model="item.vueloc_tca" :disabled="isViewModal()" />

                <DatePickerEsp
                  label="Fecha ETD"
                  :date="item.fecha_etd"
                  :isRequired="false"
                  name="fecha_etd"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_etd = date;
                    }
                  "
                />

                <DatePickerEsp
                  label="Fecha ETA"
                  :date="item.fecha_eta"
                  :isRequired="false"
                  name="fecha_eta"                  
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_eta = date;
                    }
                  "
                />

                <v-combobox
                  v-model="item.arribo"
                  data-vv-as="arribo"
                  name="arribo"                                    
                  :items="['Si', 'No']"
                  label="Arribo"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>

                <v-text-field v-validate="'numeric'" max data-vv-as="pr_fc" label="PR/FC" name="pr_fc" v-model="item.pr_fc" :disabled="isViewModal()" />

                <div class="col-md-12">
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="mb-0 title-acordion">
                          <b>Facturas de Agente</b>
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>                      
                        <InputTable
                          :isViewModal="isViewModal()"
                          table_title="Facturas de Agente"
                          :validator="$validator"
                          :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' } ,{ text: 'Autorizado' } ,{ text: 'Acciones' }]"
                          :items="item.facturas_agentes"
                          :input_fields="[
                            { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false, validate: 'max:30' },
                            {
                              label: 'Seleccionar factura',
                              name: 'factura_agente_attached',
                              name_attached: 'factura_path',
                              type: 'file',
                              required: false,
                              allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                            },
                            { width: '5%', label: 'Autorizado', name: 'autorizado', type: 'checkbox', required: false },
                          ]"
                          :item_template="{
                            id: 0,
                            nro_factura: '',
                            factura_agente_attached: null,                            
                          }"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div> 
                
                <div class="col-md-12">
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <p class="mb-0 title-acordion">
                          <b>Documentaciones de Entrega</b>
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>                      
                        <InputTable
                          :isViewModal="isViewModal()"
                          table_title="Documentacion de Entrega"
                          :validator="$validator"
                          :headers="[{ text: 'Fecha' }, { text: 'Adjunto' },{ text: 'Acciones' }]"
                          :items="item.documentaciones_entrega"
                          :input_fields="[
                            { width: '20%', label: 'Fecha', name: 'fecha', type: 'date', required: true },                            
                            {
                              label: 'Seleccionar factura',
                              name: 'adjunto_attached',
                              name_attached: 'factura_path',
                              type: 'file',
                              required: false,
                              allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                            },
                            
                          ]"
                          :item_template="{
                            id: 0,
                            fecha: null,
                            adjunto_attached: null,                            
                          }"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div> 

              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="info" text @click="printModal(item)" v-if="!isViewModal() && this.item.id == 0">Imprimir</v-btn>
            <v-btn color="warning" text @click="nextModal(item)" v-if="!isViewModal() && this.item.id == 0">Siguiente</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import InputTable from "@/components/InputTable.vue";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  components: {
    DatePickerEsp,
    InputTable,
  },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    print: Function,    
  },
  data() {
    return {
      menu: false,
      show: false,
      shippers: [],
      consignees: [],
      inssuing_carriers: [],
      airports: [],
      carriers: [],
      currencies: [],
      agentes: [],
    };
  },
  watch: {
    item() {      
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({}, "agentes").then((response) => {
        this.agentes = response.result;
      });
      generalService.filter({}, "currencies").then((response) => {
        this.currencies = response.result;
      });
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "inssuing_carriers").then((response) => {
        this.inssuing_carriers = response.result;
      });
      generalService.filter({}, "airports").then((response) => {
        this.airports = response.result;
      });
      generalService.filter({}, "carriers").then((response) => {
        this.carriers = response.result;
      });
    },
    getDataToSend(){
      let data = new FormData();
      data.append("id", this.item.id);
      data.append("guia_madre", this.item.guia_madre);
      data.append("guia_hija", this.item.guia_hija);
      data.append("executed_date", this.item.executed_date);
      data.append("shipper_id", this.item.shipper.id);
      data.append("consignee_id", this.item.consignee.id);
      data.append("agente_id", this.item.agente? this.item.agente.id : "");
      
      if( this.item.inssuing_carrier)
        data.append("inssuing_carrier_id", this.item.inssuing_carrier? this.item.inssuing_carrier.id : "");

      if( this.item.departure_airport)
        data.append("departure_airport_id", this.item.departure_airport? this.item.departure_airport.id : "");

      if( this.item.transshipment_air)
        data.append("transshipment_air_id", this.item.transshipment_air? this.item.transshipment_air.id : "");
      
      if( this.item.carrier)
        data.append("carrier_id", this.item.carrier? this.item.carrier.id : "");

      if( this.item.currency)
        data.append("currency_id", this.item.currency? this.item.currency.id : "");

      if( this.item.arrival_airport)
        data.append("arrival_airport_id", this.item.arrival_airport? this.item.arrival_airport.id : "");

      if( this.item.accounting_information)
        data.append("accounting_information", this.item.accounting_information);

      if( this.item.chgs_code)
        data.append("chgs_code", this.item.chgs_code);
      
      data.append("wtval_ppd", (this.item.wtval_ppd)?1:0);
      data.append("wtval_coll",(this.item.wtval_coll)?1:0);          
      data.append("other_ppd", (this.item.other_ppd)?1:0);        
      data.append("other_coll", (this.item.other_coll)?1:0); 

      if( this.item.declared_value_for_carriage)
        data.append("declared_val ue_for_carriage", this.item.declared_value_for_carriage);

      if( this.item.declared_value_for_customs)
        data.append("declared_value_for_customs", this.item.declared_value_for_customs);   

      if( this.item.requested_flight)
        data.append("requested_flight", this.item.requested_flight);

      if( this.item.amount_insurance)
        data.append("amount_insurance", this.item.amount_insurance);

      if( this.item.handling_information)
        data.append("handling_information", this.item.handling_information);

      if( this.item.rcp)
        data.append("rcp", this.item.rcp);

      if( this.item.gross_weight)
        data.append("gross_weight", this.item.gross_weight);

      if( this.item.kilograms)
        data.append("kilograms", this.item.kilograms);

      if( this.item.rate_class)
        data.append("rate_class", this.item.rate_class);

      if( this.item.chargeable_weight)
        data.append("chargeable_weight", this.item.chargeable_weight);

      if( this.item.rate_charge)
        data.append("rate_charge", this.item.rate_charge);

      if( this.item.total)
        data.append("total", this.item.total);

      if( this.item.nature_quantity_goods)
        data.append("nature_quantity_goods", this.item.nature_quantity_goods);

      if( this.item.other_charges)
        data.append("other_charges", this.item.other_charges);

      if( this.item.total_other_charges)
        data.append("total_other_charges", this.item.total_other_charges);

      if( this.item.sim)
        data.append("sim", this.item.sim);

      if( this.item.mani)
        data.append("mani", this.item.mani);

      if( this.item.comentarios)
        data.append("comentarios", this.item.comentarios);

      if( this.item.doc)
        data.append("doc", this.item.doc);

      if( this.item.vueloc_tca)
        data.append("vueloc_tca", this.item.vueloc_tca);

      if( this.item.fecha_etd)
        data.append("fecha_etd", this.item.fecha_etd);

      if( this.item.fecha_eta)
        data.append("fecha_eta", this.item.fecha_eta);

      if( this.item.arribo)
        data.append("arribo", this.item.arribo);

      if( this.item.pr_fc)
        data.append("pr_fc", this.item.pr_fc);     
      
      //Tablads de archivos
      data.append("cantidad_facturas_agente", this.item.facturas_agentes.length);
      if (this.item.facturas_agentes.length > 0) {
        for (var j = 0; j < this.item.facturas_agentes.length; j++) {        
          const autorizado_value = (this.item.facturas_agentes[j].autorizado) ? 1 : 0;      
          data.append("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
          data.append("facturas_agentes_" + j + "_nro_factura", this.item.facturas_agentes[j].nro_factura);
          data.append("facturas_agentes_" + j + "_attached", this.item.facturas_agentes[j].factura_agente_attached);
          data.append("facturas_agentes_" + j + "_autorizado", autorizado_value);              
        }
      }
      
      data.append("cantidad_documentaciones_entrega", this.item.documentaciones_entrega.length);
      if (this.item.documentaciones_entrega.length > 0) {
        for (var z = 0; z < this.item.documentaciones_entrega.length; z++) {              
          data.append("documentaciones_entrega_" + z + "_id", this.item.documentaciones_entrega[z].id);
          data.append("documentaciones_entrega_" + z + "_fecha", this.item.documentaciones_entrega[z].fecha);
          data.append("documentaciones_entrega_" + z + "_attached", this.item.documentaciones_entrega[z].adjunto_attached);              
          console.log(this.item.documentaciones_entrega[z].fecha)
        }
      }

      return data;
    },
    createItem(e, nextFlag = false) {
      if(this.loading)
        return;
       
      return this.$validator.validate().then((valid) => {        
        if (valid) {
          this.setLoading(true); 
          if (e != null) e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }

          const data = this.getDataToSend();
          if (this.item.id > 0) {
            return this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> this.setLoading(false));
          } else {
            return orderService
              .create(data)
              .then((response) => {                
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false, nextFlag);
                  this.$emit("loadList");
                  if (nextFlag) this.$validator.reset();
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {                
                this.$toast.error(response.msg, "Error");
                return response;
              })
              .finally(()=> this.setLoading(false));
          }
        }
      });
    },

    printModal() {
      this.createItem(null).then((response) => {
        this.$emit("print", response.result, "pdf");
      });
    },

    nextModal() {
      this.createItem(null, true);
    },
  },
};
</script>
