<template>
    <v-dialog v-model="dialog" max-width="600px" @keydown.esc="$emit('close')">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Historial de {{ title }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headers" :items="history" item-key="id" :loading="loading">
                    <template v-slot:item="props">
                    <tr>
                        <td v-for="property in properties" :key="property">
                            {{ typeof props.item[property] === 'boolean' ? (props.item[property] ? 'Sí' : 'No') : props.item[property] }}
                        </td>
                    </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "HistoryModal",
    props: {
        dialog: Boolean,
        title: String,
        id: Number,
        service: Object,
        method: String,
        item: Object,
        headers: Array,
        properties: Array,
        close: Function,
    },
    data() {
        return {
            history: [],
            loading: false,
        };
    },
    mounted() {
        this.loadHistory();
    },
    watch: {
        dialog(value){
            if(value) {
                this.loadHistory();
            }
        }
    },
    computed: {},
    methods: {               
        loadHistory() {            
            if(this.item != null) {
               
                if(this.loading) return;
                
                this.loading = true;
                this.service[this.method]({ id: this.id })
                .then(response => {
                    if(response.status == true) {
                        this.history = response.result;
                    }                    
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
            
    }
};
</script>
