<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <!-- TODO: BORRAR -->
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->      
            <div class="row mt-3" ref="section">      
              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="operacion" label="Operacion*" name="operacion" v-model="item.operacion" v-validate="'required|max:30'" />
                <span class="red--text">{{ errors.first("operacion") }}</span>
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.shipper"
                  data-vv-as="shipper"
                  name="shipper"
                  item-text="name"
                  item-value="id"
                  :items="shippers"
                  label="Shiper"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("shipper") }}</span>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-combobox
                  v-model="item.consignee"
                  data-vv-as="consignee"
                  name="consignee"
                  item-text="name"
                  item-value="id"
                  :items="consignees"
                  label="Consignee"
                  autocomplete="off"
                  :disabled="true"
                ></v-combobox>
                <span class="red--text">{{ errors.first("consignee") }}</span>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Packing List</b>
                    </v-expansion-panel-header>
                    <!-- READ ONLY -->
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="true"
                        table_title="Packing List"
                        :validator="$validator"
                        :headers="[{ text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.packing_lists"
                        :input_fields="[
                          {
                            label: 'Seleccionar packing list',
                            name: 'packing_list_attached',
                            name_attached: 'packing_list_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          packing_list_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b> Facturas</b>
                      <!-- READ ONLY -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="true"
                        table_title="Facturas"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false, validate: 'max:30' },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>              

              <div v-if="!item.lcl" class="col-md-12">                

                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <p class="mb-0 title-acordion">
                        <b>Camiones</b>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field data-vv-as="cantidad de camiones" label="Cantidad de Camiones" name="cantidad_camiones" v-model="item.cantidad_camiones" v-validate="'integer|min:0'" :disabled="isViewModal()" />
                      <span class="red--text">{{ errors.first("cantidad_camiones") }}</span>

                      <InputTable
                        :isViewModal="isViewModal()"
                        :validator="$validator"
                        :headers="[{ text: 'Tipo contenedor' }, { text: 'Matricula Remolque' }, { text: 'Matricula Tractor' }, { text: 'Bultos' }, { text: 'KGs' }, { text: 'M3' }, { text: 'Acciones' }]"
                        :items="item.camiones"
                        :input_fields="[                          
                          { label: 'Tipo Camion', name: 'tipo_camion', type: 'select', required: false, list: tipos_camiones, list_id: 'id', list_name: 'name' },
                          { label: 'Matricula Remolque*', name: 'matricula_remolque', type: 'text', required: false },
                          { label: 'Matricula Tractor', name: 'matricula_tractor', type: 'text', required: false },                          
                          { label: 'Bultos', name: 'bultos', type: 'numeric', required: false, validate: 'numeric|min:0' },
                          { label: 'KGs', name: 'kgs', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                          { label: 'M3', name: 'm3', type: 'numeric', required: false, validate: 'decimal:2|min:0' },
                        ]"
                        :item_template="{
                          id: 0,
                          id_contenedor: '',
                          numero: '',
                          tipo_contenedor: null,
                          kgs: '',
                          33: '',
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="crt" label="CRT" name="crt" v-model="item.crt" :disabled="true" />
                <span class="red--text">{{ errors.first("crt") }}</span>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <v-text-field data-vv-as="mic" label="MIC" name="mic" v-model="item.mic" :disabled="true" />
                <span class="red--text">{{ errors.first("mic") }}</span>
              </div>

              <div class="col-md-4">                
                <v-text-field data-vv-as="referencia_cliente" label="Referencia cliente" name="referencia_cliente" v-model="item.referencia_cliente" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("referencia_cliente") }}</span>
              </div>

              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Recoleccion"
                  :date="item.fecha_recoleccion"
                  :isRequired="false"
                  name="fecha_recoleccion"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Recoleccion (Confirmada)"
                  :date="item.fecha_recoleccion_confirmada"
                  :isRequired="false"
                  name="fecha_recoleccion_confirmada"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_recoleccion_confirmada = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <!-- READ ONLY -->
                <DatePickerEsp
                  label="Fecha Salida (ETD)*"
                  :date="item.fecha_salida_etd"
                  :isRequired="false"
                  name="fecha_salida_etd"
                  :isViewModal="true"
                  @setDate="
                    (date) => {
                      item.fecha_salida_etd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Salida (ATD)"
                  :date="item.fecha_salida_atd"
                  :isRequired="false"
                  name="fecha_salida_atd"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_salida_atd = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Llegada (ETA)"
                  :date="item.fecha_llegada_eta"
                  :isRequired="false"
                  name="fecha_llegada_eta"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_eta = date;
                    }
                  "
                />
              </div>
              <div class="col-md-4">
                <DatePickerEsp
                  label="Fecha Llegada (ATA)"
                  :date="item.fecha_llegada_ata"
                  :isRequired="false"
                  name="fecha_llegada_ata"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.fecha_llegada_ata = date;
                    }
                  "
                />
              </div>

              <div class="col-md-12">
                <v-combobox
                  v-model="item.ultimo_estado"
                  data-vv-as="ultimo_estado"
                  name="ultimo_estado"
                  item-text="name"
                  item-value="id"
                  :items="estados"
                  label="Ultimo Estado"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("ultimo_estado") }}</span>
              </div>
              <div class="col-md-12">
                <v-textarea outlined data-vv-as="comentarios" label="Comentarios" name="comentarios" v-model="item.comentarios" :disabled="isViewModal()"></v-textarea>
                <span class="red--text">{{ errors.first("comentarios") }}</span>
              </div>

              <div class="col-md-4">
                <v-combobox
                  v-model="item.lugar_salida"
                  data-vv-as="lugar_salida"
                  name="lugar_salida"
                  item-text="name"
                  item-value="id"
                  :items="lugares"
                  label="Lugar de salida"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("lugar_salida") }}</span>
              </div>              
              <div class="col-md-4">
                <v-combobox
                  v-model="item.lugar_llegada"
                  data-vv-as="lugar_llegada"
                  name="lugar_llegada"
                  item-text="name"
                  item-value="id"
                  :items="lugares"
                  label="Lugar de llegada"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("lugar_llegada") }}</span>
              </div>

              <div class="col-md-12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Facturas de Agente</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InputTable
                        :isViewModal="isViewModal()"
                        table_title="Facturas de Agente"
                        :validator="$validator"
                        :headers="[{ text: 'Nº Factura' }, { text: 'Adjunto' }, { text: 'Acciones' }]"
                        :items="item.facturas_agentes"
                        :input_fields="[
                          { width: '20%', label: 'Nº de Factura*', name: 'nro_factura', type: 'text', required: false, validate: 'max:30' },
                          {
                            label: 'Seleccionar factura',
                            name: 'factura_agente_attached',
                            name_attached: 'factura_path',
                            type: 'file',
                            required: false,
                            allowed_mime_types: ['application/pdf', 'image/jpg', 'image/png'],
                          },
                        ]"
                        :item_template="{
                          id: 0,
                          nro_factura: '',
                          factura_agente_attached: null,
                        }"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>                 

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <v-checkbox label="Proformas Control" :disabled="isViewModal()" v-model="item.proformas_control"></v-checkbox>
                  </div>

                  <div class="col-md-10">
                    <v-text-field v-validate="'max:30'" data-vv-as="proformas_numero" label="Proformas Nº" name="proformas_numero" v-model="item.proformas_numero" :disabled="isViewModal()" />
                    <span class="red--text">{{ errors.first("proformas_numero") }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <v-combobox
                  v-model="item.compania_transporte"
                  data-vv-as="compania_transporte"
                  name="compania_transporte"
                  item-text="name"
                  item-value="id"
                  :items="companias_transporte"
                  label="Compania de transporte"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("compania_transporte") }}</span>
              </div>   

              <div class="col-md-6">
                <v-combobox
                  v-model="item.terminal_dep_fiscal"
                  data-vv-as="terminal_dep_fiscal"
                  name="terminal_dep_fiscal"
                  item-text="name"
                  item-value="id"
                  :items="terminales_dep_fiscal"
                  label="Terminal/Dep. Fiscal"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("terminal_dep_fiscal") }}</span>
              </div>

              <div class="col-md-12">
                <v-textarea outlined data-vv-as="observaciones" label="Observaciones" name="observaciones" v-model="item.observaciones" :disabled="isViewModal()"></v-textarea>
                <span class="red--text">{{ errors.first("observaciones") }}</span>
              </div>  
            </div>                          
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
            
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import ModalMixin from "@/mixins/ModalMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import InputTable from "@/components/InputTable.vue";

export default {
  mixins: [ModalMixin, RolMixin],
  components: { DatePickerEsp, InputTable },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    print: Function,
  },
  data() {
    return {
      menu: false,
      show: false,
      shippers: [],
      consignees: [],
      estados: [],
      lugares: [],      
      tipos_camiones: [],      
      codigos_puertos: [],
      agentes: [],
      terminales_dep_fiscal: [],
      companias_transporte: [],
    };
  },
  watch: {
    item: {
      handler(val) {
        this.$emit("updateItem", this.item);
      },
      deep: true,
    },

    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    setDateValue(value, name) {
      this.item[name] = value;
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "estados").then((response) => {
        this.estados = response.result;
      });
      generalService.filter({}, "lugares").then((response) => {
        this.lugares = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "tipos_camiones").then((response) => {
        this.tipos_camiones = response.result;
      });
      
      generalService.filter({}, "agentes").then((response) => {
        this.agentes = response.result;
      });
      generalService.filter({}, "terminales_dep_fiscal").then((response) => {
        this.terminales_dep_fiscal = response.result;
      });
      generalService.filter({}, "companias_transporte").then((response) => {
        this.companias_transporte = response.result;
      });
      
    },
    customValidate(){
      var result = true;
      
      result = result && this.customValidateComboBoxField(this.item.shipper,"shipper","Shipper");
      result = result && this.customValidateComboBoxField(this.item.consignee,"consignee","Consignee");
      result = result && this.customValidateComboBoxField(this.item.ultimo_estado,"ultimo_estado","Ultimo Estado");
      result = result && this.customValidateComboBoxField(this.item.lugar_salida,"lugar_salida","Lugar de salida");
      result = result && this.customValidateComboBoxField(this.item.lugar_llegada,"lugar_llegada","Lugar de llegada");      
      result = result && this.customValidateComboBoxField(this.item.terminal_dep_fiscal,"terminal_dep_fiscal","Terminal/Dep. Fiscal");      
      result = result && this.customValidateComboBoxField(this.item.compania_transporte,"compania_transporte","Compania de transporte");

      if(this.item.camiones != null && this.item.camiones.length > 0){
        for(var c = 0; c < this.item.camiones.length; c++){          
          result = result && this.customValidateComboBoxField(this.item.camiones[c].tipo_c,'tipo_camion_' + c,"tipo de camion");
        }
      }        
      return result;
    },
    createItem(e, nextFlag = false) {
      return this.$validator.validate().then((valid) => {
        if (valid && this.customValidate()) {
          if (e != null) e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          this.setLoading(true);

          let data = new FormData();
          data.append("id", this.item.id);
          data.append("operacion", this.item.operacion);
          data.append("shipper_id", this.item.shipper.id);
          data.append("consignee_id", this.item.consignee.id);
          
          data.append("proformas_control", this.item.proformas_control ? 1 : 0);          

          if (this.item.referencia_cliente != null) data.append("referencia_cliente", this.item.referencia_cliente);

          if (this.item.fecha_salida_atd != null)
            data.append("fecha_salida_atd", this.item.fecha_salida_atd);

          if (this.item.proformas_numero != null) data.append("proformas_numero", this.item.proformas_numero);          
          if (this.item.observaciones != null) data.append("observaciones", this.item.observaciones);
          
          if (this.item.ultimo_estado != null) data.append("ultimo_estado_id", this.item.ultimo_estado.id);
          if (this.item.lugar_salida != null) data.append("lugar_salida_id", this.item.lugar_salida.id);
          if (this.item.lugar_llegada != null) data.append("lugar_llegada_id", this.item.lugar_llegada.id);
          if (this.item.compania_transporte != null) data.append("compania_transporte_id", this.item.compania_transporte.id);
          if (this.item.agente != null) data.append("agente_id", this.item.agente.id);
          if (this.item.terminal_dep_fiscal != null) data.append("terminal_dep_fiscal_id", this.item.terminal_dep_fiscal.id);          

          if (this.item.fecha_llegada_eta != null) data.append("fecha_llegada_eta", this.item.fecha_llegada_eta);
          if (this.item.fecha_llegada_ata != null) data.append("fecha_llegada_ata", this.item.fecha_llegada_ata);
          if (this.item.comentarios != null) data.append("comentarios", this.item.comentarios);

          if (this.item.cantidad_camiones != null) data.append("cantidad_camiones", this.item.cantidad_camiones);
          data.append("cantidad_camiones_arr", this.item.camiones.length);          
          if (this.item.camiones.length > 0) {
            for (var i = 0; i < this.item.camiones.length; i++) {
              data.append("camiones_arr[]", JSON.stringify(this.item.camiones[i]));
            }
          }

          //no se pueden adjuntar archivos en un array anidado
          data.append("cantidad_facturas_agente", this.item.facturas_agentes.length);
          if (this.item.facturas.length > 0) {
            for (var j = 0; j < this.item.facturas_agentes.length; j++) {
              data.append("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
              data.append("facturas_agentes_" + j + "_nro_factura", this.item.facturas_agentes[j].nro_factura);
              data.append("facturas_agentes_" + j + "_attached", this.item.facturas_agentes[j].factura_agente_attached);
              console.log("facturas_agentes_" + j + "_id", this.item.facturas_agentes[j].id);
            }
          }          

          if (this.item.id > 0) {
            return this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => this.setLoading(false));
          } else {
            return this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false, nextFlag);
                  this.$emit("loadList");
                  if (nextFlag) this.$validator.reset();
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
                return response;
              })
              .finally(() => this.setLoading(false));
          }
        }
      });
    },

    printModal() {
      this.createItem(null).then((response) => {
        this.$emit("print", response.result, "pdf");
      });
    },

    nextModal() {
      this.createItem(null, true);
    },
  },
};
</script>
